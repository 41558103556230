<template>
    <span class="countdown">
        <span v-if="showDays" class="days">{{ days }}</span>
        <span class="hours">{{ hours }}</span>
        <span class="minutes">{{ minutes }}</span>
        <span class="seconds">{{ seconds }}</span>
    </span>
</template>

<script>
    export default {
        name: 'Countdown',

        props: {
            deadline: Date,
            showDays: Boolean,
        },

        data() {
            return {
                realDeadline: null, // usado quando a data final do contador é diferente da data final da oferta
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        },

        watch: {
            deadline: {
                immediate: true,
                handler(date) {
                    if (date) {
                        this.realDeadline = date.getTime();
                    }
                },
            },
        },

        mounted() {
            this.update();
            setInterval(this.update.bind(this), 1000);
        },

        methods: {
            getValues() {
                const now = new Date();
                const diff = this.realDeadline ? this.realDeadline - now.getTime() : 0;

                if (diff < 0) {
                    return {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    };
                }

                const hours = Math.floor(diff / (60 * 60 * 1000));

                return {
                    days: Math.floor(diff / (60 * 60 * 1000 * 24)),
                    hours: this.showDays ? hours % 24 : hours,
                    minutes: Math.floor(diff / (60 * 1000)) % 60,
                    seconds: Math.floor(diff / 1000) % 60,
                };
            },
            update() {
                const values = this.getValues();
                this.days = values.days;
                this.hours = values.hours;
                this.minutes = values.minutes;
                this.seconds = values.seconds;
            },
        },
    };
</script>
