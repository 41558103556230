import { EventBus } from './common';
import cr$ from '@bit/chicorei.chicomponents.script.crquery';
import { CLOSE_DESKTOP_MENU_EVENT, SHOW_MOBILE_MENU_EVENT } from './events';

const getScrollHandler = (headerEl) => {
    // find elements
    const desktopHeaderEl = cr$.byId('header-desktop');
    const topbarEl = cr$.byId('header-topbar');
    const mobileTopbarWrapperEl = cr$.byId('header-mobile-topbar-wrapper');
    const desktopTopbarWrapperEl = cr$.byId('header-desktop-topbar-wrapper');
    const bodyEl = cr$.queryOne('body');

    // auxiliary values
    let previousScroll;
    const originalTopBarHeight = topbarEl.raw.offsetHeight;
    const minTopBarHeight = 48;

    let isShowingShadow = false;
    const changeShadow = (showShadow, el) => {
        if (isShowingShadow === showShadow) return;

        if (showShadow) {
            el.addClass('shadow');
        } else {
            el.removeClass('shadow');
        }

        isShowingShadow = !isShowingShadow;
    };

    const scrollForNormalScreens = (scroll) => {
        desktopTopbarWrapperEl.css('transform', 'translateY(0)');

        // para não alterar a altura da barra do topo enquanto as categorias estiverem visíveis
        const scrollValueForHeight = Math.max(0, scroll);
        const percentage = Math.min(1, scrollValueForHeight / originalTopBarHeight);
        const newHeight =
            minTopBarHeight + (1 - percentage) * (originalTopBarHeight - minTopBarHeight) + 'px';

        topbarEl.css('height', newHeight);

        //if (percentage > 0) {
        //   EventBus.$emit(CLOSE_DESKTOP_MENU_EVENT);
        //}

        changeShadow(percentage > 0, desktopHeaderEl);

        desktopTopbarWrapperEl.css('transform', `translateY(${Math.max(0, -scroll)}px)`);

        if (percentage === 1) {
            headerEl.addClass('categories-slide-enabled');
        } else {
            headerEl.removeClass('categories-slide-enabled');
        }
    };

    const scrollForSmallScreens = (scroll) => {
        headerEl.removeClass('categories-slide-enabled');

        const diff = scroll - (previousScroll || 0);

        if (Math.abs(diff) >= 10) {
            if (previousScroll > scroll || scroll < mobileTopbarWrapperEl.raw.offsetHeight) {
                mobileTopbarWrapperEl.css('transform', 'translateY(0)');
            } else {
                mobileTopbarWrapperEl.css('transform', 'translateY(-100%)');
            }
        }
    };

    return () => {
        const scroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (
            window.innerWidth < 768 &&
            (scroll < 0 || bodyEl.raw.classList.contains('only-search-visible'))
        ) {
            return;
        }

        if (window.innerWidth >= 768) {
            scrollForNormalScreens(scroll);
        } else {
            scrollForSmallScreens(scroll);
        }

        previousScroll = scroll;
    };
};

const initHeader = () => {
    const desktopHeaderEl = cr$.byId('header-desktop');
    if (desktopHeaderEl.exists()) {
        // slide down desktop menu
        cr$.byId('header-desktop-logo-wrapper').on('mouseenter', () =>
            desktopHeaderEl.addClass('do-slide')
        );

        const scrollHandler = getScrollHandler(desktopHeaderEl);

        scrollHandler();
        window.addEventListener('scroll', scrollHandler, { passive: true });
    }

    const headerDrawerEl = cr$.byId('header-drawer-icon');
    if (headerDrawerEl.exists()) {
        // open drawer on icon click
        headerDrawerEl.click(() => EventBus.$emit(SHOW_MOBILE_MENU_EVENT));
    }
};

cr$.ready(function () {
    requestIdleCallback(() => {
        initHeader();
    });
});

export const hideMobileHeader = () => {
    if (window.innerWidth < 768) {
        const scroll = document.body.scrollTop || document.documentElement.scrollTop;
        const mobileTopbarWrapperEl = cr$.byId('header-mobile-topbar-wrapper');

        if (mobileTopbarWrapperEl.exists() && scroll > mobileTopbarWrapperEl.raw.offsetHeight) {
            mobileTopbarWrapperEl.css('transform', 'translateY(-100%)');
            mobileTopbarWrapperEl.removeClass('shadow');
        }
    }
};
