<template>
    <div class="header-cart-loading">
        <div class="hidden-sm-up">
            <div class="d-flex flex-align-center text-primary hidden-sm-up" @click="hide">
                <span class="icon-cr icon-chevron-left" />
                {{ $t('_shop.closeBag') }}
            </div>
        </div>

        <h2 id="cart-fast-title" class="mt section-title">
            {{ $t('_shop.yourBag') }}
        </h2>

        <Spinner v-if="loading" class="my" />
        <template v-else-if="cart && cart.last_products && cart.last_products.data.length > 0">
            <ul class="px-0 py-0 mb-auto">
                <li
                    v-for="cartProduct in cart.last_products.data"
                    class="product-list-item d-flex flex-spaced-fixed"
                >
                    <div class="product-list-item-thumb flex-child-fixed">
                        <img
                            v-if="getProductImage(cartProduct)"
                            :alt="cartProduct.product.name"
                            :src="getProductImage(cartProduct)"
                            class="img-responsive rounded overflow-hidden"
                            height="75"
                            width="65"
                        />
                    </div>
                    <div>
                        <p class="product-list-item-title">
                            {{ cartProduct.product.name }}
                        </p>
                        <p class="product-list-item-subtitle">
                            <span v-if="cartProduct.product_model_name">
                                {{ cartProduct.product_model_name }}
                                <span class="hidden-sm-down">|</span>
                                <br class="hidden-sm-up" />
                            </span>

                            {{ cartProduct.size }}
                            <br />
                            {{ $t('quantity') }}: {{ cartProduct.quantity }}
                        </p>
                    </div>
                    <p class="product-list-item-price">
                        {{ $f.formatMoney(cartProduct.total_price) }}
                    </p>
                    <br />
                </li>
            </ul>

            <hr />

            <div class="cart-footer">
                <div class="text-center">
                    <p v-if="cart.count_products > 2">
                        {{ $tc('_shop.productsInYourBagX', cart.count_products - 2) }}
                    </p>
                    <template v-if="cart.total_discounts > 0">
                        <p>
                            <span> {{ $t('subtotal') }} </span>
                            <span v-if="cart.last_products.data.length > cart.count_products">
                                ({{ $tc('_shop.items', count) }})
                            </span>
                            <span>: {{ $f.formatMoney(cart.total_products) }}</span>
                        </p>
                        <p>
                            {{ $t('_shop.discounts') }}: -
                            {{ $f.formatMoney(cart.total_discounts) }}
                        </p>
                    </template>
                    <p class="text-bold">
                        <span> {{ $t('_shop.total') }} </span>
                        <span
                            v-if="
                                cart.total_discounts === 0 &&
                                cart.last_products.data.length > cart.count_products
                            "
                        >
                            ({{ count }} itens)
                        </span>
                        <span
                            >:
                            {{
                                $f.formatMoney(
                                    Math.max(0, cart.total_products - cart.total_discounts)
                                )
                            }}</span
                        >
                        <span
                            v-if="
                                Math.max(0, cart.total_products - cart.total_discounts) > 20 &&
                                $scope.IS_REGULAR_CUSTOMER
                            "
                            class="text-secondary"
                        >
                            {{ $t('or').toLowerCase() }} 3x
                            {{
                                $f.formatMoney(
                                    Math.floor(
                                        (Math.max(0, cart.total_products - cart.total_discounts) /
                                            3) *
                                            100
                                    ) / 100
                                )
                            }}
                        </span>
                    </p>
                </div>
            </div>

            <hr />
        </template>
        <div v-else class="h5">{{ $t('_shop.youHaveNotAddedItems') }} :(</div>

        <div v-if="!cart || cart.count_products > 0" class="text-center">
            <!-- Botão de levar ao carrinho aqui -->
            <a class="btn btn-lg mb" :href="`${$scope.URL_STORE_PREFIX}/sacola`" target="_self">
                {{ $t('_shop.toViewBag') }}
            </a>
            <a
                class="h5 mx-0 my-0 text-accent text-underline cursor-pointer"
                :href="`${$scope.URL_STORE_PREFIX}/sacola/checkout`"
                target="_self"
            >
                {{ $t('_shop.toFinishOrder') }}
            </a>
        </div>
    </div>
</template>

<script>
    import { getProductThumbLink } from '../../../common/common';
    import { cartStore } from '../../../common/store/cart-store';
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';

    export default {
        name: 'CartFastContent',

        components: { Spinner },

        props: {
            loading: Boolean,
            cart: Object,
            count: Number,
            iscartpath: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                storeState: cartStore.state,
            };
        },

        computed: {
            visible() {
                return this.storeState.showFastCart;
            },
        },

        watch: {
            visible(to) {
                if (to) {
                    setTimeout(() => window.addEventListener('click', this.onWindowClick), 700);
                } else {
                    window.removeEventListener('click', this.onWindowClick);
                }
            },
        },

        methods: {
            getProductImage: function (cartProduct) {
                const productModel = (cartProduct.product.models || []).find(
                    (m) => m.id === cartProduct.product_model_id
                );
                const fallbackImage =
                    (productModel || {}).main_image || cartProduct.product.img_cover;

                const pixelRatio = Math.max(1, Math.min(2, window.devicePixelRatio));

                return getProductThumbLink(
                    fallbackImage,
                    cartProduct.product.img_thumb_png,
                    cartProduct.fabric_id,
                    {
                        auto: 'compress,format',
                        q: 65,
                        w: Math.round(65 * pixelRatio),
                        h: Math.round(75 * pixelRatio),
                        fit: 'crop',
                        crop: 'entropy',
                    },
                    cartProduct.product.fabric_id == cartProduct.fabric_id
                );
            },

            onWindowClick(event) {
                if (
                    event.target.id !== 'cart-fast-content-mobile' &&
                    !cr$.byId('cart-fast-content-mobile').raw.contains(event.target)
                ) {
                    this.hide();
                }
            },

            hide() {
                cartStore.hide();
            },
        },
    };
</script>